import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./Routes";
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { showError } from "./lib/notifLib";
import { Navbar, AppShell, Header, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import {
  Group,
  ActionIcon,
  useMantineColorScheme,
  Box,
  rem,
  Container,
} from "@mantine/core";
import { MantineNavbar } from "./components/MantineNavbar";

function App() {
  const nav = useNavigate();

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        showError(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    nav("/login");
  }

  function navNotes() {
    nav("/notes");
  }
  function navRecords() {
    nav("/records");
  }
  function navReminders() {
    nav("/reminders");
  }

  return (
    !isAuthenticating && (
      <MantineProvider withNormalizeCSS withGlobalStyles>
        <Container>
          <Notifications position="bottom-right" autoClose={5000} />
          <MantineNavbar
            links={[
              { link: "/notes", label: "Notes", action: navNotes },
              { link: "/records", label: "Records", action: navRecords },
              { link: "/reminders", label: "Reminders", action: navReminders },
              { link: "/login", label: "Logout", action: handleLogout },
            ]}
          />
          <AppContext.Provider
            value={{
              isAuthenticated,
              userHasAuthenticated,
            }}
          >
            <Routes />
          </AppContext.Provider>
        </Container>
      </MantineProvider>
    )
  );
}

export default App;
