import React from "react";
import { Center } from "@mantine/core";

export default function NotFound() {
  return (
    <Center>
      <h3>Sorry, page not found!</h3>
    </Center>
  );
}
