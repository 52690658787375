import React, { useState, useEffect } from "react";
import {
  Button,
  LoadingOverlay,
  NativeSelect,
  List,
  Text,
  NavLink,
  Divider,
  TextInput,
  Group,
  Title,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { getNotes, getRecords } from "../lib/api";
import { showError } from "../lib/notifLib";
import { getUpdatedAtText } from "../lib/utils";
import { IconCirclePlus } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export default function Notes() {
  const [visible, { open, close }] = useDisclosure(false);
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(
    JSON.parse(localStorage.getItem("selectedRecord")) || null
  );
  const [notes, setNotes] = useState([]);

  const nav = useNavigate();

  // Filters
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 500);

  const [dateRangeValue, setDateRangeValue] = useState([null, null]);
  const [debounceDateRangeValue] = useDebouncedValue(dateRangeValue, 500);

  useEffect(() => {
    async function loadRecords() {
      open();
      try {
        const records = await getRecords();
        setRecords(records);

        const selectedRecord = JSON.parse(
          localStorage.getItem("selectedRecord")
        );
        if (!selectedRecord) {
          localStorage.setItem("selectedRecord", JSON.stringify(records[0]));
          setSelectedRecord(records[0]);
        }
      } catch (e) {
        showError(e);
      }
      close();
    }
    loadRecords();
  }, [open, close]);

  useEffect(() => {
    loadNotes();
  }, [selectedRecord, open, close]);

  useEffect(() => {
    if (
      (debounceDateRangeValue[0] != null &&
        debounceDateRangeValue[1] == null) ||
      (debounceDateRangeValue[0] == null && debounceDateRangeValue[1] != null)
    ) {
      return;
    }
    loadNotes();
  }, [debounceDateRangeValue]);

  useEffect(() => {
    loadNotes();
  }, [debouncedSearchValue]);

  async function loadNotes() {
    if (!selectedRecord) {
      close();
      return;
    }
    try {
      open();
      const notes = await getNotes(
        selectedRecord.id,
        debounceDateRangeValue[0],
        debounceDateRangeValue[1],
        debouncedSearchValue
      );
      setNotes(notes);
    } catch (e) {
      showError(e);
    }
    close();
  }

  return (
    <>
      {/* Overlay */}
      <LoadingOverlay visible={visible} />

      <Group position="apart" align="end">
        <Title order={2}>Notes</Title>

        {/* New note button */}
        <Button
          mt="xl"
          leftIcon={<IconCirclePlus />}
          onClick={() => {
            if (!selectedRecord) {
              return;
            }
            nav(`/records/${selectedRecord.id}/notes/new`);
          }}
        >
          New note
        </Button>
      </Group>

      <Group mt="sm">
        {/* Select */}
        <NativeSelect
          label="Record"
          placeholder="Select a record"
          data={records.map((r) => {
            return { value: r.id, label: r.name };
          })}
          value={selectedRecord?.id}
          withAsterisk
          onChange={(e) => {
            const record = records.find(
              (record) => record.id === e.target.value
            );
            localStorage.setItem("selectedRecord", JSON.stringify(record));
            setSelectedRecord(record);
          }}
          miw={210}
          maw={210}
        />

        {/* Date range */}
        <DatePickerInput
          type="range"
          label="Date Range"
          placeholder="Select date range"
          value={dateRangeValue}
          onChange={setDateRangeValue}
          miw={210}
          maw={330}
          clearable
        />

        {/* Search */}
        <TextInput
          label="Search"
          placeholder="Search"
          value={searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          miw={210}
          maw={210}
        />

        {/* Tags */}
        {/* <MultiSelect
          data={[
            { value: "Tag1", label: "Tag1" },
            { value: "Tag2", label: "Tag2" },
            { value: "Tag3", label: "Tag3" },
            { value: "Tag4", label: "Tag4" },
            { value: "Tag5", label: "Tag5" },
            { value: "Tag6", label: "Tag6" },
            { value: "Tag7", label: "Tag7" },
            { value: "Tag8", label: "Tag8" },
            { value: "Tag9", label: "Tag9" },
          ]}
          label="Tags"
          placeholder="Select tags"
          // defaultValue={["react", "next"]}
          clearButtonProps={{ "aria-label": "Clear selection" }}
          clearable
          miw={210}
          maw={210}
        /> */}
      </Group>

      {/* List */}
      <List icon={<></>} spacing={0} mt="sm">
        <Divider />
        {notes.map(
          ({ id, title, content, attachment_urls, created_at, updated_at }) => (
            <div key={id}>
              {/* List item */}
              <NavLink
                label={
                  <Text weight={500} size="lg">
                    {/* {content.trim().split("\n")[0]} */}
                    {title}
                  </Text>
                }
                description={
                  <>
                    <Text mt="xs" color="dimmed" size="sm">
                      {new Date(created_at).toDateString()}&nbsp;
                      {new Date(created_at).toLocaleTimeString()}
                    </Text>
                    <Text mt="xs" color="dimmed" size="sm">
                      Updated&nbsp;{getUpdatedAtText(updated_at)}
                    </Text>

                    {attachment_urls && attachment_urls.length > 0 && (
                      <Text mt="xs" color="dimmed" size="sm">
                        {attachment_urls.length} attachments
                      </Text>
                    )}
                  </>
                }
                component="a"
                href={`/notes/${id}`}
              />
              <Divider />
            </div>
          )
        )}
      </List>
    </>
  );
}
