import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showError } from "../lib/notifLib";
import {
  Button,
  Group,
  LoadingOverlay,
  MultiSelect,
  Switch,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronLeft, IconEdit, IconTrash } from "@tabler/icons-react";
import { deleteReminder, getReminder } from "../lib/api";
import { DateTimePicker } from "@mantine/dates";

export default function Reminder() {
  const [visible, { open, close }] = useDisclosure(false);
  const nav = useNavigate();
  const { reminderId } = useParams();

  const [reminder, setReminder] = useState({
    name: "",
    content: "",
    reminder_date: null,
    is_recurring: false,
    phone_numbers: [],
  });

  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [reminderDate, setReminderDate] = useState(null);
  const [isRecurring, setIsRecurring] = useState(false);

  useEffect(() => {
    async function loadReminder() {
      open();
      try {
        const reminder = await getReminder(reminderId);
        setReminder(reminder);
      } catch (e) {
        showError(e);
      }
      close();
    }

    loadReminder();
  }, [reminderId, open, close]);

  async function handleDelete() {
    try {
      open();
      await deleteReminder(reminder.id);
    } catch (e) {
      showError(e);
    }
    close();
    nav(-1);
  }

  return (
    <>
      <LoadingOverlay visible={visible} />

      {/* Name */}
      <TextInput
        label="Name"
        required
        // value={name}
        // onChange={(e) => setName(e.currentTarget.value)}
        value={reminder.name}
        readOnly
      />

      {/* Content */}
      <Textarea
        mt="xs"
        label="Message"
        autosize
        minRows={5}
        maxRows={5}
        // value={content}
        // onChange={(e) => setContent(e.currentTarget.value)}
        value={reminder.content}
        readOnly
      />

      {/* Reminder Date */}
      <DateTimePicker
        mt="xs"
        dropdownType="modal"
        valueFormat="DD MMM YYYY hh:mm A"
        label="Pick date and time"
        placeholder="Pick date and time"
        maw={400}
        // value={reminderDate}
        // onChange={setReminderDate}
        value={reminder.reminder_date ? new Date(reminder.reminder_date) : null}
        readOnly
      />

      {/* Recurring */}
      <Switch
        mt="xs"
        label="Recurring?"
        // checked={isRecurring}
        // onChange={(event) => setIsRecurring(event.currentTarget.checked)}
        checked={reminder.is_recurring}
        readOnly
      />

      {/* Phone numbers */}
      <MultiSelect
        mt="xs"
        data={reminder.phone_numbers.map((num) => {
          return { value: num, label: num };
        })}
        value={reminder.phone_numbers}
        label="Phone numbers"
        readOnly
      />

      <Group mt="sm" position="right">
        <Button color="red" leftIcon={<IconTrash />} onClick={handleDelete}>
          Delete
        </Button>
        <Button
          variant="outline"
          leftIcon={<IconChevronLeft />}
          onClick={() => nav(-1)}
        >
          Back
        </Button>
      </Group>
    </>
  );
}
