import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { showError } from "../lib/notifLib";
import { Button, Group, LoadingOverlay, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { getRecord, updateRecord, deleteRecord } from "../lib/api";
import { IconChevronLeft, IconEdit, IconTrash } from "@tabler/icons-react";

export default function Record() {
  const [visible, { open, close }] = useDisclosure(false);
  const { recordId } = useParams();
  const nav = useNavigate();

  const [record, setRecord] = useState(null);

  const [name, setName] = useState("");

  useEffect(() => {
    async function loadRecord() {
      open();
      try {
        const record = await getRecord(recordId);
        setRecord(record);
        setName(record.name);
      } catch (e) {
        showError(e);
      }
      close();
    }

    loadRecord();
  }, [recordId, open, close]);

  async function handleUpdate() {
    try {
      open();
      await updateRecord({
        id: record.id,
        name: name,
      });
    } catch (e) {
      showError(e);
    }
    close();
    nav(-1);
  }

  async function handleDelete() {
    try {
      open();
      await deleteRecord(record.id);
    } catch (e) {
      showError(e);
    }
    close();
    nav(-1);
  }

  return (
    <>
      <LoadingOverlay visible={visible} />

      {/* Name */}
      <TextInput
        label="Name"
        required
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
      />

      <Group mt="sm" position="right">
        <Button color="teal" leftIcon={<IconEdit />} onClick={handleUpdate}>
          Update
        </Button>
        <Button color="red" leftIcon={<IconTrash />} onClick={handleDelete}>
          Delete
        </Button>
        <Button
          variant="outline"
          leftIcon={<IconChevronLeft />}
          onClick={() => nav(-1)}
        >
          Back
        </Button>
      </Group>
    </>
  );
}
