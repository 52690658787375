import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { showError } from "../lib/notifLib";
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { deleteNote, getNote, updateNote } from "../lib/api";
import { IconChevronLeft, IconEdit, IconTrash } from "@tabler/icons-react";
import { UploadDropzone } from "../components/UploadDropzone";

export default function Note() {
  const [visible, { open, close }] = useDisclosure(false);
  const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const { noteId } = useParams();
  const nav = useNavigate();

  const [note, setNote] = useState(null);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    async function loadNote() {
      open();
      try {
        const note = await getNote(noteId);
        setNote(note);
        setTitle(note.title);
        setContent(note.content);
      } catch (e) {
        showError(e);
      }
      close();
    }

    loadNote();
  }, [noteId, open, close]);

  async function handleUpdate() {
    try {
      open();
      await updateNote({
        id: note.id,
        title: title,
        content: content,
        attachment_urls: note.attachment_urls,
        record_id: note.record_id,
      });
    } catch (e) {
      showError(e);
    }
    close();
    nav(-1);
  }

  async function handleDelete() {
    closeModal();
    try {
      open();
      await deleteNote(note.id);
    } catch (e) {
      showError(e);
    }
    close();
    nav(-1);
  }

  return (
    <>
      <LoadingOverlay visible={visible} />
      <Modal
        opened={opened}
        onClose={closeModal}
        title="Confirm Delete"
        centered
      >
        {/* Modal content */}
        <Text size="sm">Do you wish to continue?</Text>
        <Group position="right">
          <Button variant="subtle" onClick={closeModal}>
            No
          </Button>
          <Button variant="subtle" onClick={handleDelete}>
            Yes
          </Button>
        </Group>
      </Modal>

      {/* Title */}
      <TextInput
        label="Title"
        required
        value={title}
        onChange={(e) => setTitle(e.currentTarget.value)}
      />

      {/* Content */}
      <Textarea
        mt="xs"
        label="Content"
        autosize
        minRows={10}
        maxRows={40}
        value={content}
        onChange={(e) => setContent(e.currentTarget.value)}
        // readOnly
      />

      {/* Attachments */}
      {/* <Text fz="sm" mt="sm">
        Attachments
      </Text>
      <UploadDropzone /> */}

      <Group mt="sm" position="right">
        <Button color="teal" leftIcon={<IconEdit />} onClick={handleUpdate}>
          Update
        </Button>
        <Button color="red" leftIcon={<IconTrash />} onClick={openModal}>
          Delete
        </Button>
        <Button
          variant="outline"
          leftIcon={<IconChevronLeft />}
          onClick={() => nav(-1)}
        >
          Back
        </Button>
      </Group>
    </>
  );
}
