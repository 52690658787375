import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { showError } from "../lib/notifLib";
import { Button, Group, LoadingOverlay, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronLeft, IconEdit } from "@tabler/icons-react";
import { createRecord } from "../lib/api";

export default function NewRecord() {
  const [visible, { open, close }] = useDisclosure(false);
  const nav = useNavigate();

  const [name, setName] = useState("");

  async function handleSave() {
    try {
      open();
      await createRecord({
        name: name,
      });
    } catch (e) {
      showError(e);
    }
    close();
    nav(-1);
  }

  return (
    <>
      <LoadingOverlay visible={visible} />

      {/* Name */}
      <TextInput
        label="Name"
        required
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
      />

      <Group mt="sm" position="right">
        <Button color="teal" leftIcon={<IconEdit />} onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="outline"
          leftIcon={<IconChevronLeft />}
          onClick={() => nav(-1)}
        >
          Back
        </Button>
      </Group>
    </>
  );
}
