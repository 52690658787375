import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewNote from "./containers/NewNote";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Notes from "./containers/Notes";
import Records from "./containers/Records";
import Note from "./containers/Note";
import Reminders from "./containers/Reminders";
import NewReminder from "./containers/NewReminder";
import Record from "./containers/Record";
import NewRecord from "./containers/NewRecord";
import Reminder from "./containers/Reminder";

export default function Links() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/login"
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/notes"
        element={
          <AuthenticatedRoute>
            <Notes />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/notes/:noteId"
        element={
          <AuthenticatedRoute>
            <Note />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/records"
        element={
          <AuthenticatedRoute>
            <Records />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/records/:recordId"
        element={
          <AuthenticatedRoute>
            <Record />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/records/new"
        element={
          <AuthenticatedRoute>
            <NewRecord />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/records/:recordId/notes/new"
        element={
          <AuthenticatedRoute>
            <NewNote />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/reminders"
        element={
          <AuthenticatedRoute>
            <Reminders />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/reminders/:reminderId"
        element={
          <AuthenticatedRoute>
            <Reminder />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/reminders/new"
        element={
          <AuthenticatedRoute>
            <NewReminder />
          </AuthenticatedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
      <Route
        path="/signup"
        element={
          <UnauthenticatedRoute>
            <Signup />
          </UnauthenticatedRoute>
        }
      />
    </Routes>
  );
}
