import {
  Button,
  Divider,
  Group,
  List,
  LoadingOverlay,
  NavLink,
  Text,
  Title,
} from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { getUpdatedAtText } from "../lib/utils";
import { useDisclosure } from "@mantine/hooks";
import { getReminders } from "../lib/api";
import { showError } from "../lib/notifLib";
import { useNavigate } from "react-router-dom";

export default function Reminders() {
  const [visible, { open, close }] = useDisclosure(false);
  const nav = useNavigate();

  const [reminders, setReminders] = useState([]);

  useEffect(() => {
    async function loadReminders() {
      open();
      try {
        const reminders = await getReminders();
        setReminders(reminders);
      } catch (e) {
        showError(e);
      }
      close();
    }

    loadReminders();
  }, [open, close]);
  return (
    <>
      {/* Overlay */}
      <LoadingOverlay visible={visible} />
      <Group position="apart" align="end">
        <Title order={2}>Reminders</Title>

        {/* New note button */}
        <Button
          mt="xl"
          leftIcon={<IconCirclePlus />}
          onClick={() => nav(`/reminders/new`)}
        >
          New reminder
        </Button>
      </Group>

      {/* List */}
      <List icon={<></>} spacing={0} mt="sm">
        <Divider />
        {reminders.map(
          ({
            id,
            org_id,
            name,
            content,
            reminder_date,
            is_recurring,
            created_at,
            updated_at,
          }) => (
            <div key={id}>
              {/* List item */}
              <NavLink
                label={
                  <Text weight={500} size="lg">
                    {name}
                  </Text>
                }
                description={
                  <>
                    <Text mt="xs" color="dimmed" size="sm">
                      {new Date(created_at).toDateString()}&nbsp;
                      {new Date(created_at).toLocaleTimeString()}
                    </Text>
                    <Text mt="xs" color="dimmed" size="sm">
                      Updated&nbsp;{getUpdatedAtText(updated_at)}
                    </Text>
                  </>
                }
                component="a"
                href={`/reminders/${id}`}
              />
              <Divider />
            </div>
          )
        )}
      </List>
    </>
  );
}
