import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { showError } from "../lib/notifLib";
import {
  Button,
  Group,
  LoadingOverlay,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronLeft, IconEdit } from "@tabler/icons-react";
import { UploadDropzone } from "../components/UploadDropzone";
import { createNote } from "../lib/api";

export default function Note() {
  const [visible, { open, close }] = useDisclosure(false);
  const nav = useNavigate();

  const { recordId } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);

  async function handleSave() {
    try {
      open();
      await createNote({
        title: title,
        content: content,
        record_id: recordId,
      });
    } catch (e) {
      showError(e);
    }
    close();
    nav(-1);
  }

  return (
    <>
      <LoadingOverlay visible={visible} />

      {/* Title */}
      <TextInput
        label="Title"
        required
        value={title}
        onChange={(e) => setTitle(e.currentTarget.value)}
      />

      {/* Content */}
      <Textarea
        label="Content"
        autosize
        minRows={10}
        maxRows={40}
        value={content}
        onChange={(e) => setContent(e.currentTarget.value)}
      />

      {/* Attachments */}
      {/* <Text fz="sm" mt="sm">
        Attachments
      </Text> */}

      {/* Attachments names here */}
      {/* {files.map((file) => {
        return <Text fz="sm">{file.name}</Text>;
      })} */}

      {/* <UploadDropzone
        onDrop={(acceptedFiles) => setFiles([...files, ...acceptedFiles])}
      /> */}

      <Group mt="sm" position="right">
        <Button color="teal" leftIcon={<IconEdit />} onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="outline"
          leftIcon={<IconChevronLeft />}
          onClick={() => nav(-1)}
        >
          Back
        </Button>
      </Group>
    </>
  );
}
