export function getUpdatedAtText(updatedAt) {
  // Get the current time
  const now = new Date();
  const start = new Date(updatedAt);

  // Calculate the difference between the start and end times
  const diffInMs = Math.abs(now - start); // absolute value to ensure positive difference

  // Convert the difference into days, hours, minutes, and seconds
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInHours = Math.floor(
    (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
  const diffInSeconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

  // Calculate the difference in months
  const startMonth = start.getMonth();
  const endMonth = now.getMonth();
  const diffInMonths = (endMonth + 12 - startMonth) % 12;

  // Calculate the difference in years
  // const startYear = start.getFullYear();
  // const endYear = now.getFullYear();
  // const diffInYears = endYear - startYear;

  if (diffInMonths) {
    return `${diffInMonths} months ago`;
  }
  if (diffInDays) {
    return `${diffInDays} days ago`;
  }
  if (diffInHours) {
    return `${diffInHours} hours ago`;
  }
  if (diffInMinutes) {
    return `${diffInMinutes} minutes ago`;
  }
  if (diffInSeconds) {
    return `${diffInSeconds} seconds ago`;
  }

  return "";
}

export function formatISODate(date, daysAdded = 0) {
  const d = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate() + daysAdded
  );
  return d.toISOString().split("T")[0];
}
