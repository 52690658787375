import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { showError } from "../lib/notifLib";
import {
  Button,
  Group,
  LoadingOverlay,
  MultiSelect,
  Switch,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronLeft, IconEdit } from "@tabler/icons-react";
import { createReminder } from "../lib/api";
import { DateTimePicker } from "@mantine/dates";

export default function NewReminder() {
  const [visible, { open, close }] = useDisclosure(false);
  const nav = useNavigate();

  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [reminderDate, setReminderDate] = useState(null);
  const [isRecurring, setIsRecurring] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  async function handleSave() {
    try {
      open();
      await createReminder({
        name: name,
        content: content,
        reminder_date: reminderDate,
        is_recurring: isRecurring,
        phone_numbers: phoneNumbers,
      });
    } catch (e) {
      showError(e);
    }
    close();
    nav(-1);
  }

  return (
    <>
      <LoadingOverlay visible={visible} />

      {/* Name */}
      <TextInput
        label="Name"
        required
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
      />

      {/* Content */}
      <Textarea
        mt="xs"
        label="Message"
        autosize
        minRows={5}
        maxRows={5}
        value={content}
        onChange={(e) => setContent(e.currentTarget.value)}
      />

      {/* Reminder Date */}
      <DateTimePicker
        mt="xs"
        dropdownType="modal"
        valueFormat="DD MMM YYYY hh:mm A"
        label="Pick date and time"
        placeholder="Pick date and time"
        maw={400}
        value={reminderDate}
        onChange={setReminderDate}
        minDate={new Date()}
      />

      {/* Recurring */}
      <Switch
        mt="xs"
        label="Recurring?"
        checked={isRecurring}
        onChange={(event) => setIsRecurring(event.currentTarget.checked)}
      />

      {/* Phone numbers */}
      <MultiSelect
        mt="xs"
        data={[
          { value: "+14373338016", label: "+14373338016" },
          { value: "+16472416561", label: "+16472416561" },
          { value: "+16474098583", label: "+16474098583" },
          { value: "+16473002586", label: "+16473002586" },
        ]}
        label="Phone numbers"
        placeholder="Select phone numbers for this reminder"
        onChange={setPhoneNumbers}
      />

      <Group mt="sm" position="right">
        <Button color="teal" leftIcon={<IconEdit />} onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="outline"
          leftIcon={<IconChevronLeft />}
          onClick={() => nav(-1)}
        >
          Back
        </Button>
      </Group>
    </>
  );
}
