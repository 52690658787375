import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";

export function showError(error) {
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    message = error.message;
  }

  notifications.show({
    title: "Something went wrong!",
    message: message,
    color: "red",
    icon: <IconX />,
    withBorder: true,
  });
}

export function showSuccess(message, title = "Success!") {
  notifications.show({
    title: title,
    message: message,
    color: "green",
    icon: <IconCheck />,
  });
}
