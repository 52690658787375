import React, { useState, useEffect } from "react";
import { useAppContext } from "../lib/contextLib";
import { showError } from "../lib/notifLib";
import "./Home.css";
import { LinkContainer } from "react-router-bootstrap";
import { getRecords } from "../lib/api";
import { getNotes } from "../lib/api";
import { Link, useNavigate } from "react-router-dom";
import { getUpdatedAtText } from "../lib/utils";

export default function Home() {
  const nav = useNavigate();
  const [records, setRecords] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoadingRecords, setIsLoadingRecords] = useState(true);
  const [isLoadingNotes, setIsLoadingNotes] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [notes, setNotes] = useState([]);

  // useEffect(() => {
  //   async function loadRecords() {
  //     if (!isAuthenticated) {
  //       return;
  //     }
  //     try {
  //       const records = await getRecords();
  //       setRecords(records);
  //     } catch (e) {
  //       showError(e);
  //     }
  //     setIsLoadingRecords(false);
  //   }
  //   loadRecords();
  // }, []);

  // useEffect(() => {
  //   async function loadNotes() {
  //     if (!isAuthenticated || !selectedRecord) {
  //       setIsLoadingNotes(false);
  //       return;
  //     }
  //     try {
  //       setIsLoadingNotes(true);
  //       const notes = await getNotes(selectedRecord.id);
  //       setNotes(notes);
  //     } catch (e) {
  //       showError(e);
  //     }
  //     setIsLoadingNotes(false);
  //   }
  //   loadNotes();
  // }, [selectedRecord]);

  useEffect(() => {
    if (isAuthenticated) {
      nav("/notes");
    }
  });

  // function renderNotes(notes) {
  //   return (
  //     <div className="Notes">
  //       {notes && (
  //         <>
  //           <div className="notes-container">
  //             <List
  //               sx={{
  //                 width: "100%",
  //                 maxWidth: "100%",
  //               }}
  //             >
  //               {notes.map(({ id, content, created_at, updated_at }) => (
  //                 <>
  //                   <ListItemButton
  //                     onClick={() => nav(`/records/${id}/notes`)}
  //                     sx={{
  //                       borderBottom: "solid",
  //                       borderBottomColor: "lightgray",
  //                       borderWidth: "thin",
  //                     }}
  //                   >
  //                     <ListItemText
  //                       primary={
  //                         <div style={{ fontWeight: 500 }}>
  //                           {content.trim().split("\n")[0]}
  //                         </div>
  //                       }
  //                       secondary={
  //                         <>
  //                           <div>
  //                             {new Date(created_at).toDateString()}
  //                             &nbsp;
  //                             {new Date(created_at).toLocaleTimeString()}
  //                           </div>
  //                           <div>
  //                             Updated&nbsp;
  //                             {getUpdatedAtText(updated_at)}
  //                           </div>
  //                         </>
  //                       }
  //                     />
  //                   </ListItemButton>
  //                 </>
  //               ))}
  //             </List>
  //           </div>
  //         </>
  //       )}
  //     </div>
  //   );
  // }

  // function renderNotesPage() {
  //   return (
  //     <div className="notes-page">
  //       {isLoadingRecords && <SimpleBackdrop />}

  //       <div className="notes-header">
  //         <h4>Your Notes</h4>
  //         <MuiOutlinedButton
  //           name="New note"
  //           onClick={() => {
  //             if (!selectedRecord) return;
  //             nav(`/records/${selectedRecord.id}/notes/new`);
  //           }}
  //         />
  //       </div>

  //       <hr />

  //       <div style={{ marginRight: 2, marginBottom: 10 }}>
  //         <TextField
  //           className="me-4"
  //           id="outlined-basic"
  //           label="Search"
  //           variant="outlined"
  //           size="small"
  //           sx={{ width: "150px" }}
  //         />

  //         <FormControl style={{ width: "150px", marginRight: 5 }}>
  //           <InputLabel id="demo-simple-select-label" size="small">
  //             Record
  //           </InputLabel>
  //           <Select
  //             id="demo-simple-select"
  //             label={
  //               (selectedRecord && selectedRecord.name) || "Select a Record"
  //             }
  //             onChange={(e) => {
  //               setSelectedRecord(
  //                 records.find((record) => record.id === e.target.value)
  //               );
  //             }}
  //             size="small"
  //           >
  //             {!isLoadingRecords &&
  //               records &&
  //               records.map(({ id, name, created_at, updated_at }) => (
  //                 <MenuItem value={id}>{name}</MenuItem>
  //               ))}
  //           </Select>
  //         </FormControl>
  //       </div>

  //       {isLoadingNotes && <SimpleBackdrop />}
  //       {!isLoadingNotes && notes && renderNotes(notes)}
  //     </div>
  //   );
  // }

  // function renderLander() {
  //   return (
  //     <div className="lander">
  //       <h1>Notefy</h1>
  //       {/* <p className="text-muted">A note + notification system</p> */}
  //     </div>
  //   );
  // }

  return (
    <div className="Home">
      <div className="lander">
        <h1>Notefy</h1>
        {/* <p className="text-muted">A note + notification system</p> */}
      </div>
    </div>
  );
}
