import {
  Button,
  Divider,
  Group,
  List,
  LoadingOverlay,
  NavLink,
  Text,
  Title,
} from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { getUpdatedAtText } from "../lib/utils";
import { useDisclosure } from "@mantine/hooks";
import { getRecords } from "../lib/api";
import { showError } from "../lib/notifLib";
import { useNavigate } from "react-router-dom";

export default function Records() {
  const [visible, { open, close }] = useDisclosure(false);
  const nav = useNavigate();

  const [records, setRecords] = useState([]);

  useEffect(() => {
    async function loadRecords() {
      open();
      try {
        const records = await getRecords();
        setRecords(records);
      } catch (e) {
        showError(e);
      }
      close();
    }

    loadRecords();
  }, [open, close]);
  return (
    <>
      {/* Overlay */}
      <LoadingOverlay visible={visible} />
      <Group position="apart" align="end">
        <Title order={2}>Records</Title>

        {/* New note button */}
        <Button
          mt="xl"
          leftIcon={<IconCirclePlus />}
          onClick={() => nav(`/records/new`)}
        >
          New record
        </Button>
      </Group>

      {/* List */}
      <List icon={<></>} spacing={0} mt="sm">
        <Divider />
        {records.map(({ id, name, created_at, updated_at }) => (
          <div key={id}>
            {/* List item */}
            <NavLink
              label={
                <Text weight={500} size="lg">
                  {name}
                </Text>
              }
              description={
                <>
                  <Text mt="xs" color="dimmed" size="sm">
                    {new Date(created_at).toDateString()}&nbsp;
                    {new Date(created_at).toLocaleTimeString()}
                  </Text>
                  <Text mt="xs" color="dimmed" size="sm">
                    Updated&nbsp;{getUpdatedAtText(updated_at)}
                  </Text>
                </>
              }
              component="a"
              href={`/records/${id}`}
            />
            <Divider />
          </div>
        ))}
      </List>
    </>
  );
}
