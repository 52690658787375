import { API } from "aws-amplify";
import { formatISODate } from "./utils";

const API_NAME = "notefy";

// TODO: This must be set in context when the user signs in
const ORG_ID = "2O7pBGByaVAc2hy5C33IVyymTmY";

export function getRecords() {
  return API.get(API_NAME, "/records", {
    queryStringParameters: {
      org_id: ORG_ID,
    },
  });
}

export function getRecord(id) {
  return API.get(API_NAME, "/records/" + id);
}

export function updateRecord(record) {
  record.org_id = ORG_ID;
  return API.put(API_NAME, "/records/" + record.id, {
    body: record,
  });
}

export function createRecord(record) {
  record.org_id = ORG_ID;
  return API.post(API_NAME, "/records", {
    body: record,
  });
}

export function deleteRecord(id) {
  return API.del(API_NAME, "/records/" + id);
}

export function getNotes(record_id, startDate, endDate, searchText) {
  const queryParams = {
    record_id: record_id,
  };
  if (startDate && endDate) {
    queryParams["start"] = formatISODate(startDate);
    queryParams["end"] = formatISODate(endDate, 1);
  }

  queryParams["searchText"] = searchText;

  return API.get(API_NAME, "/notes", {
    queryStringParameters: queryParams,
  });
}

export function createNote(note) {
  return API.post(API_NAME, "/notes", {
    body: note,
  });
}

export function getNote(id) {
  return API.get(API_NAME, "/notes/" + id);
}

export function updateNote(note) {
  return API.put(API_NAME, "/notes/" + note.id, {
    body: note,
  });
}

export function deleteNote(id) {
  return API.del(API_NAME, "/notes/" + id);
}

/**
 * ReminderDto:
    org_id: str
    name: str
    content: str
    reminder_date: datetime
    is_recurring: bool
 * 
 */

export function getReminders() {
  return API.get(API_NAME, "/reminders", {
    queryStringParameters: {
      org_id: ORG_ID,
    },
  });
}

export function getReminder(id) {
  return API.get(API_NAME, "/reminders/" + id);
}

export function updateReminder(reminder) {
  reminder.org_id = ORG_ID;
  return API.put(API_NAME, "/reminders/" + reminder.id, {
    body: reminder,
  });
}

export function createReminder(reminder) {
  reminder.org_id = ORG_ID;
  return API.post(API_NAME, "/reminders", {
    body: reminder,
  });
}

export function deleteReminder(id) {
  return API.del(API_NAME, "/reminders/" + id);
}
